import { graphql } from "gatsby";
import * as React from "react";
import Page from "../../templates/page";

// markup
export const query = graphql`
	query FrontArabicpageQuery {
		sanityPage(slug: { current: { eq: "ar/home" } }, i18n_lang: { eq: "ar" }) {
			i18n_lang
			title
			_rawContent(resolveReferences: { maxDepth: 10 })
		}
	}
`;
const IndexPage = ({ data, location }) => {
	return (
		<>
			<Page location={location} data={data} />
		</>
	);
};

export default IndexPage;
